import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    senderId:       Number,
    createdAt:      String,
    messageId:      Number,
    isUnreaded:     Boolean,
    conversationId: Number,
    newlyAdded:     Boolean
  }

  connect() {
    console.log('Message item controller connected!');
    const thisElement    = $(this.element);
    const prevElement    = thisElement.prevAll('.message-item');
    const senderId       = this.senderIdValue;
    const createdAt      = new Date(this.createdAtValue);
    const thisMessageId  = this.messageIdValue;
    const isUnreaded     = this.isUnreadedValue;
    const conversationId = this.conversationIdValue;
    const newlyAdded     = this.newlyAddedValue;
    const todayDate      = new Date();

    const element = thisElement.find(".message-text")[0];
    if(element){
      element.innerHTML = element.innerHTML.replace(/(https?:\/\/[^\s]+)/g, "<a href='$1' target='_blank'>$1</a>");
    }
    if (newlyAdded) {
      const currentChatItem = $(`#conversation_item_${conversationId}`);

      if (currentChatItem && currentChatItem.length > 0) {
        $('.chat-item-tab').removeClass('has_latest_message');
        currentChatItem.addClass('has_latest_message');
      }
    }

    let currentUserId

    if ($("#general-message_to_be_append").data()) {
      currentUserId = $("#general-message_to_be_append").data().currentUserId;
    } else if ($("#proposal-message_to_be_append").data()) {
      currentUserId = $("#proposal-message_to_be_append").data().currentUserId;
    }

    // Getting yesterday date
    const date           = new Date();
    const yesterdayDate  = new Date(date.setDate(date.getDate() - 1));

    const hideSenderInfo = () => {
      thisElement.addClass('child-messages');
      thisElement.find('.sender-thumb').addClass('hidden');
      thisElement.find('.message-sent-time').addClass('hidden');
    }

    const isMessageCreatedToday = () => {
      return createdAt.setHours(0,0,0,0) === todayDate.setHours(0,0,0,0)
    }

    const isMessageCreatedYesterday = () => {
      return createdAt.setHours(0,0,0,0) === yesterdayDate.setHours(0,0,0,0)
    }

    const dateSeparatorText = () => {
      if (isMessageCreatedToday()) {
        return 'Today';
      } else if (isMessageCreatedYesterday()) {
        return 'Yesterday';
      } else {
        return false;
      }
    }

    const showDateSeparator = () => {
      if (dateSeparatorText()) {
        $(`#general-chat-separator-text-${thisMessageId}`).text(dateSeparatorText());
        $(`#proposal-chat-separator-text-${thisMessageId}`).text(dateSeparatorText());
      }

      $(`#general-chat-separator-${thisMessageId}`).removeClass('hidden');
      $(`#proposal-chat-separator-${thisMessageId}`).removeClass('hidden');
    }

    const showUnreadCell = () => {
      $('.unread_cell').addClass('hidden');
      $(`#general-unread-cell-${thisMessageId}`).removeClass('hidden');
      $(`#proposal-unread-cell-${thisMessageId}`).removeClass('hidden');
    }

    if (prevElement && prevElement.data()) {
      let prevMessageSenderId  = prevElement.data().messageItemSenderIdValue;
      let prevMessageCreatedAt = new Date(prevElement.data().messageItemCreatedAtValue);

      if ((prevMessageSenderId === senderId) && ((Math.abs(createdAt - prevMessageCreatedAt)/1000) < 300)) {
        hideSenderInfo();
      }

      if (createdAt.setHours(0,0,0,0) !== prevMessageCreatedAt.setHours(0,0,0,0)) {
        showDateSeparator();
      }
    } else {
      showDateSeparator();
    }

    if (isUnreaded && currentUserId !== senderId && !$(`.conversation-messages-${conversationId}`).is(':visible')) {
      showUnreadCell()
    }

    $('.image_format').click(function(){
      var imgSrc = $(this).attr('src');
      $("#msg_img").attr("src", imgSrc);
      $('.dwnld_btn').attr('href', imgSrc);
      $('#img_preview').modal('show');
    });
  }
}
